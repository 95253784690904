import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
class Header extends React.Component{
    constructor(props)
    {
        super(props);
        this.state = {
            mobile_menu_open:false
        }
    }

    render(){
        return(
            <div className = "headerBar">
                <div className = "header">
                    <div className = "header-wrapper">
                        <div className = "logo">
                            <NavLink to={`/contacts`}>
                                RANDOM USER
                            </NavLink>
                        </div>
                     </div>
                </div>
                <hr/>
            </div>
        )
    }
}

export default Header;
  