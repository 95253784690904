import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

/** Import service reducers */
import ModalReducer from '../modal/modalConductorReducer';


export default combineReducers({
  routing: routerReducer,
  modal:ModalReducer
});
