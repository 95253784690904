import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

// Import scenes
import Home from './Home/Home';
import ModalConductor from '../modal/ModalConductor';

class App extends React.Component {
  render() {
    return (
      <main>
        <Router>
          <Switch>
            <Route path="/" render={props => <Home {...props} />} />
          </Switch>
        </Router>
        <ModalConductor/>
      </main>
    );
  }
}

export default App;
