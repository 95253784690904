import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import ProfileCard from './components/ProfileCard/ProfileCard';

import './view.css';
class UserView extends React.Component{
    constructor(props)
    {
        super(props);
        // this.props.productActions.getProducts("");
        this.state = {
            users: new Array(),
            loading: true
        }
        this.users = new Array();
        this.getUsers();

    }
    async getUsers(){
        for(var i = 0 ;i < 10 ;i++)
        {
            let res =  axios.get("https://randomuser.me/api");
            await res.then((res)=>{
                console.log(res.data.results[0]);
                if(res.data.results[0].location.country.split(' ').length == 1)
                this.users.push( res.data.results[0] );
            })
        }
        this.setState({
            users: this.users,
            loading:false
        })
    };
    componentWillMount(){
        // console.log(this.users);
    }
    renderUsers(){
        if(this.state.users.length == 0){
            return <div className = "view-wrapper"></div>
        } else {
            const users = this.state.users.map((user, index) => (
                <div key = {index} >
                    <ProfileCard 
                        userInfo = {user}

                    />
                </div>
            ));
            return(
                <div className = "view-wrapper"><div>{users}</div></div>
            )
        }
    }
    render(){
        if(this.state.loading)
        {
            Swal.fire({
                "text":"loading...",
                allowOutsideClick: false,
                onOpen:()=>{
                    Swal.showLoading();
                }
            })
        } else {
            Swal.close();
        }
        return(
            <div >
                {this.renderUsers()}
            </div>
        )
    }
}
export default UserView;
  