import React from 'react';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';

import {showModal}  from '../../../../../../modal/modalConductorActions';
import './ProfileCard.css';
class ProductCard extends React.Component{
    constructor(props)
    {
        super(props);
        this.gotoDetails = this.gotoDetails.bind(this);
    }
    gotoDetails(userInfo){
        this.props.productActions.showModal("DETAIL_USER_MODAL", userInfo);
    }
    render()
    {
        return(
           
               <div>
                    <div className = "profile-card"  onClick = {()=>this.gotoDetails(this.props.userInfo)}>
                        <div className = "picture-area">
                                <img src = {this.props.userInfo.picture.large}
                                    alt = ""
                                />
                        </div>
                        <div className = "info">
                            {/* <img src = {"https://assets.thebasetrip.com/api/v2/countries/flags/"+this.props.userInfo.location.country.toLowerCase() + ".png"}/> */}
                            <div className = "name">
                                {this.props.userInfo.name.first + " " + this.props.userInfo.name.last}
                            </div>
                            <div className = "des">
                               Contact:  {this.props.userInfo.phone}
                            </div>
                            <div className = "des">
                               Email:  {this.props.userInfo.email}
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default connect(
   null,
    dispatch => ({
        productActions: bindActionCreators(
            {  showModal},
            dispatch
        )
    })
  )(ProductCard);