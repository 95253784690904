import React from 'react';
import { connect } from 'react-redux';

// Import Modals
import DetailUserModal from './components/DetailUser';

const ModalConductor = props => {
  switch (props.modal.modalType) {
    case 'DETAIL_USER_MODAL':
      return <DetailUserModal {...props} />;
    default:
      return null;
  }
};

export default connect(
  state => ({
    modal: {
      ...state.default.modal
    }
  }),
  null
)(ModalConductor);
