import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import ModalWrapper from '../ModalWrapper';
class DetailUser  extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }


  render() {
    return (
      <ModalWrapper
        title={this.props.modal.params.name.first + " " + this.props.modal.params.name.last}
      >
        <Form className="mt-3">
          <FormGroup>
            <img src = {this.props.modal.params.picture.large} alt = "user-avatar" style = {{width:"100%"}}/>
          </FormGroup>
          <FormGroup className = "name">
            <label><strong>Name:</strong> {this.props.modal.params.name.first + " " + this.props.modal.params.name.last}</label>
          </FormGroup>
          <FormGroup className = "name">
            <label><strong>Email:</strong> {this.props.modal.params.email}</label>
          </FormGroup>
          <FormGroup className = "name">
            <label><strong>Birthday:</strong> {this.props.modal.params.dob.date.substr(0,10)}</label>
          </FormGroup>
          <FormGroup className = "name">
            <label><strong>Contact:</strong> {this.props.modal.params.phone}</label>
          </FormGroup>
          <FormGroup className = "name">
            <label><strong>Country:</strong><img style = {{width:"50px",marginLeft:"30px"}} src = {"https://assets.thebasetrip.com/api/v2/countries/flags/"+this.props.modal.params.location.country.toLowerCase() + ".png"}/> </label>

          </FormGroup>
        </Form>
      </ModalWrapper>
    );
  }
}
export default DetailUser;