import React from 'react';
import {Input} from 'reactstrap';
import './Filter.css';

class Filter extends React.Component{
    constructor(props)
    {
        super(props);
    }
    render(){
        return(
            <div className = "filter-wrapper">
                <div className = "search">
                    <Input placeholder="Please type username for searching users...." />
                </div>
            </div>
        )
    }
}

export default  Filter;